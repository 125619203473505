import React from 'react';
import styled from 'styled-components';
import { brandYellow } from './colors';
import TechnologyFlowAdvanced from './TechnologyFlowAdvanced';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';

const TechSectionWrapper = styled.section`
  background: #fff;
  color: #333;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
`;

const TechContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  gap: 3rem;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const Heading = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #555;
`;

const BulletList = styled.ul`
  list-style: inside;
  margin-bottom: 2rem;
  color: #555;
`;

const BulletItem = styled.li`
  margin-bottom: 0.75rem;
`;

const CtaButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  max-width: 450px;
  height: 300px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 0.9rem;
`;

export default function TechnologySection() {
  const goToFreePlan = useGoToFreePlan();
  return (
    <TechSectionWrapper>
      <TechContainer>

          <TechnologyFlowAdvanced/>

      </TechContainer>
    </TechSectionWrapper>
  );
}