import React from 'react';
import styled from 'styled-components';
import { brandYellow } from './colors';
import { MockRecordingsUI } from './MockRecordingsUI';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';

/* 1) SECTION WRAPPER */
const SectionWrapper = styled.section`
  background: linear-gradient(135deg, rgb(0, 113, 225) 0%, rgb(0, 179, 179) 100%);
  position: relative;
  padding: 18rem 2rem 6rem;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    padding: 10rem 1rem 4rem; /* Less top padding on smaller devices */
  }
`;

/* 2) TRIANGLE TOP */
const TriangleTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  overflow: hidden;

  svg {
    display: block;
    width: calc(100% + 1.3px);
    height: 100%;
  }

  .shape-fill {
    fill: #fff; /* White triangle */
  }
  
    @media (max-width: 1100px) {
    display: none;
  }
`;

/* 3) CONTENT LAYOUT */
const ContentContainer = styled.div`
  z-index: 2;
  max-width: 1400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start; /* left align columns when stacked */
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const Heading = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: #fff;
`;

const Paragraph = styled.p`
  font-size: 1.15rem;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 1.5rem;
`;

/* 4) TWO VERSIONS OF THE BUTTON */
const DesktopButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  /* Hide on smaller screens */
  @media (max-width: 900px) {
    display: none;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: 2rem;
    width: 100%; /* Ensure the mock UI can scale down fully */
    justify-content: center;
  }
`;

/* Wrapper for the mobile button (below the UI on small screens) */
const MobileButtonWrapper = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
    margin-top: 1.5rem; 
    width: 100%;
  }
`;

const MobileButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  /* Center horizontally if you like */
  display: block;
  margin: 0 auto;
`;

/* 5) COMPONENT */
export default function RecordingsSection() {
  const goToFreePlan = useGoToFreePlan();

  return (
    <SectionWrapper>
      {/* Downward-pointing triangle at the top */}
      <TriangleTop>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 150"
          preserveAspectRatio="none"
        >
          <path d="M0,0 L1200,0 L400,150 Z" className="shape-fill"></path>
        </svg>
      </TriangleTop>

      <ContentContainer>
        {/* Left column with text */}
        <LeftColumn>
          <Heading>Much More Than Just “Session Recordings”</Heading>
          <Paragraph>
            Most session recording tools give you a video replay of
            your users’ clicks and scrolls.
            Turning that into truly strategic insight often feels like guesswork—resulting
            in missed opportunities and frustrated teams.
          </Paragraph>
          <Paragraph>
            ClientCues goes beyond surface-level replays. Our AI-powered tagging
            and ChatGPT-style Q&amp;A mean you don’t just see what users did—you
            understand exactly why they did it. With real-time discovery of user
            frustrations and churn triggers, you can drive meaningful growth,
            not just watch it happen.
          </Paragraph>

          {/* Desktop button displayed only on larger screens */}
          <DesktopButton onClick={() => goToFreePlan('InsightsAndRecordings')}>
            Get instant insights
          </DesktopButton>
        </LeftColumn>

        {/* Right column with the mock UI */}
        <RightColumn>
          <MockRecordingsUI />
        </RightColumn>

        {/* Mobile button displayed **after** the mock UI on small screens */}
        <MobileButtonWrapper>
          <MobileButton onClick={() => goToFreePlan('InsightsAndRecordings')}>
            Get instant insights
          </MobileButton>
        </MobileButtonWrapper>
      </ContentContainer>
    </SectionWrapper>
  );
}