import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #1a1a1a;
  color: #ccc;
  padding: 2rem;
  text-align: center;
  font-size: 0.9rem;
`;

const FooterNav = styled.nav`
  margin-top: 1rem;
  a {
    color: #ccc;
    margin: 0 0.8rem;
    text-decoration: none;
  }
`;

export default function FooterSection() {
  return (
    <FooterWrapper>
      <p>©2023 Tagado Inc. All rights reserved.</p>
      <FooterNav>
        <a href="#privacy">Privacy Policy</a>
        <a href="#terms">Terms of Service</a>
        <a href="#contact">Contact Us</a>
      </FooterNav>
    </FooterWrapper>
  );
}