import React from 'react';
import styled from 'styled-components';

/*
  A “fancy” playback interface:
  - Minimal top bar (traffic-light) style
  - A main playback area with a play button
  - A bottom control bar with a timeline and markers
*/

const PlaybackWrapper = styled.div`
  width: 100%;
  max-width: 620px;
  height: 250px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  overflow: hidden;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
`;

const PlaybackTitleBar = styled.div`
  height: 32px;
  background: #e6e6e6;
  display: flex;
  align-items: center;
  padding: 0 1rem;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color || '#ccc'};
  margin-right: 6px;
`;

const PlaybackBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

// Main playback (video) area
const VideoArea = styled.div`
  flex: 1;
  background: linear-gradient(135deg, #eaf9ff 0%, #f9faff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  position: relative;
  font-size: 0.95rem;
`;

const PlayButton = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 16px;
    width: 0;
    height: 0;
    border-left: 14px solid #555;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;

// Bottom control bar with timeline and markers
const ControlBar = styled.div`
  height: 60px;
  background: #fff;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0 1rem;
`;

const ProgressContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 1rem;
`;

const TimeLabel = styled.span`
  font-size: 0.8rem;
  color: #666;
  margin-right: 0.5rem;
  min-width: 40px;
  text-align: right;
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  flex: 1;
  height: 6px;
  background: #e6e6e6;
  border-radius: 3px;
  margin: 0 0.5rem;
  cursor: pointer;
`;

const ProgressFill = styled.div`
  height: 6px;
  background: #2b00b3; /* Adjust to your brand color */
  border-radius: 3px 0 0 3px;
  width: 30%;
`;

const Marker = styled.div`
  position: absolute;
  top: 50%;
  width: 6px;
  height: 6px;
  background: #ffcc00;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  left: ${props => props.position || '50%'};
`;

const TotalTimeLabel = styled.span`
  font-size: 0.8rem;
  color: #666;
  margin-left: 0.5rem;
  min-width: 40px;
`;

const SettingsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  position: relative;
  width: 28px;
  height: 28px;

  &:before,
  &:after,
  span {
    content: "";
    position: absolute;
    background: #555;
    border-radius: 2px;
  }
  &:before {
    top: 5px;
    left: 0;
    width: 28px;
    height: 4px;
  }
  &:after {
    top: 12px;
    left: 0;
    width: 28px;
    height: 4px;
  }
  span {
    top: 19px;
    left: 0;
    width: 28px;
    height: 4px;
  }
  &:hover:before,
  &:hover:after,
  &:hover span {
    background: #000;
  }
`;

export default function FancySessionPlaybackUI() {
  return (
    <PlaybackWrapper>
      <PlaybackTitleBar>
        <Circle color="#ED594A" />
        <Circle color="#FDD800" />
        <Circle color="#5AC05A" />
      </PlaybackTitleBar>
      <PlaybackBody>
        <VideoArea>
          <PlayButton />
          <div style={{ position: 'absolute', bottom: '8px', fontSize: '0.8rem', color: '#777' }}>
            Session Replay
          </div>
        </VideoArea>
        <ControlBar>
          <ProgressContainer>
            <TimeLabel>1:12</TimeLabel>
            <ProgressBarWrapper>
              <ProgressFill />
              {/* Example markers: user errors, friction points, etc. Put them where you want. */}
              <Marker position="40%" />
              <Marker position="70%" />
            </ProgressBarWrapper>
            <TotalTimeLabel>3:45</TotalTimeLabel>
          </ProgressContainer>

          <SettingsButton>
            <span />
          </SettingsButton>
        </ControlBar>
      </PlaybackBody>
    </PlaybackWrapper>
  );
}