import React from 'react';
import styled from 'styled-components';
import FancySessionPlaybackUI from './FancySessionPlaybackUI';

// Make this container fully responsive with a max-width
const BrowserMockup = styled.div`
  width: 100%;
  max-width: 580px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: #333;
  margin-top: 1rem;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
`;

// Title bar & control circles
const TitleBar = styled.div`
  height: 32px;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${(props) => props.color || '#ccc'};
  margin-right: 6px;
`;

// Main area: left = session list, right = preview
const MainContent = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const RecordingList = styled.div`
  flex: 1;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-width: 200px;
`;

const ListHeader = styled.div`
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #ddd;
  background: #fafafa;
  font-weight: bold;
  font-size: 0.95rem;
`;

const Recordings = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecordingItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;

  &:hover {
    background: #f7f7f7;
  }
`;

const Thumbnail = styled.div`
  width: 40px;
  height: 30px;
  background: #666;
  border-radius: 4px;
  margin-right: 0.8rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
`;

const RecordingInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecordingTitle = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 4px;
`;

const RecordingMeta = styled.div`
  font-size: 0.75rem;
  color: #888;
`;

// Preview area
const PreviewPanel = styled.div`
  flex: 1.2;
  display: flex;
  flex-direction: column;
`;

const PreviewHeader = styled.div`
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #ddd;
  background: #fafafa;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PreviewBody = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SummaryContainer = styled.div`
  background: #f9f9f9;
  border-radius: 6px;
  padding: 0.8rem;
  flex: 1; /* let it fill the remaining space if needed */
  display: flex;
  flex-direction: column;
`;

const SummaryTitle = styled.h4`
  margin: 0 0 0.3rem;
  font-size: 0.95rem;
  font-weight: bold;
`;

const SummaryList = styled.ul`
  list-style-type: disc;
  margin: 0.5rem 0 0 1.2rem;
  padding: 0;
`;

const SummaryItem = styled.li`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #333;
`;

export function MockRecordingsUI() {
  return (
    <BrowserMockup>
      <TitleBar>
        <Circle color="#ED594A" />
        <Circle color="#FDD800" />
        <Circle color="#5AC05A" />
      </TitleBar>

      <MainContent>
        {/* LEFT: LIST OF SESSION RECORDINGS */}
        <RecordingList>
          <ListHeader>Session Recordings</ListHeader>
          <Recordings>
            <RecordingItem>
              <Thumbnail>
                <PlayIcon />
              </Thumbnail>
              <RecordingInfo>
                <RecordingTitle>Recording #12</RecordingTitle>
                <RecordingMeta>10/05/2023 - 2:31 min</RecordingMeta>
              </RecordingInfo>
            </RecordingItem>

            <RecordingItem>
              <Thumbnail>
                <PlayIcon />
              </Thumbnail>
              <RecordingInfo>
                <RecordingTitle>Recording #13</RecordingTitle>
                <RecordingMeta>10/05/2023 - 3:09 min</RecordingMeta>
              </RecordingInfo>
            </RecordingItem>

            <RecordingItem>
              <Thumbnail>
                <PlayIcon />
              </Thumbnail>
              <RecordingInfo>
                <RecordingTitle>Recording #14</RecordingTitle>
                <RecordingMeta>10/05/2023 - 1:55 min</RecordingMeta>
              </RecordingInfo>
            </RecordingItem>

            <RecordingItem>
              <Thumbnail>
                <PlayIcon />
              </Thumbnail>
              <RecordingInfo>
                <RecordingTitle>Recording #15</RecordingTitle>
                <RecordingMeta>Rage Clicks: 4</RecordingMeta>
              </RecordingInfo>
            </RecordingItem>

            <RecordingItem>
              <Thumbnail>
                <PlayIcon />
              </Thumbnail>
              <RecordingInfo>
                <RecordingTitle>Recording #16</RecordingTitle>
                <RecordingMeta>Session crashed</RecordingMeta>
              </RecordingInfo>
            </RecordingItem>
          </Recordings>
        </RecordingList>

        {/* RIGHT: PREVIEW/SELECTED RECORDING VIEW */}
        <PreviewPanel>
          <PreviewHeader>
            <span>Recording #12</span>
            <button
              style={{
                background: '#4895ef',
                color: '#fff',
                border: 'none',
                padding: '0.4rem 0.7rem',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '0.85rem'
              }}
            >
              Share
            </button>
          </PreviewHeader>

          <PreviewBody>
            <FancySessionPlaybackUI />
            <SummaryContainer>
              <SummaryTitle>Key Insights</SummaryTitle>
              <SummaryList>
                <SummaryItem>User encountered 2 errors on the final step</SummaryItem>
                <SummaryItem>Spent 1:30 filling form fields</SummaryItem>
                <SummaryItem>Multiple password retry attempts</SummaryItem>
                <SummaryItem>Possible friction: confusing confirmation prompt</SummaryItem>
              </SummaryList>
            </SummaryContainer>
          </PreviewBody>
        </PreviewPanel>
      </MainContent>
    </BrowserMockup>
  );
}