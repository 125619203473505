import React from 'react';
import styled from 'styled-components';
import {
  MdQuestionAnswer,
  MdTrackChanges,
  MdBuild,
  MdPsychology
} from 'react-icons/md';

import insights_reply from '../../../assets/img/homepage/sections/Holistic/insights_reply2.png';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';
import { brandYellow } from './colors';

// Example brand color; adjust if needed.
const brandBlue = '#0056b3';

/* ========== LAYOUT WRAPPERS ========== */
const FeaturesWrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 4rem 2rem;
  background: #fff;
  color: #333;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  gap: 4rem; /* Space between left & right columns */

  /* Stack columns for screens under 992px */
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

/* ========== LEFT COLUMN ========== */
const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SectionTitle = styled.h2`
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
`;

const Subtitle = styled.p`
  max-width: 450px;
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 2rem;
`;

/*
  Button shown on desktops/larger screens only.
  Hidden on screens below 992px.
*/
const DesktopCTAButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

/* ========== RIGHT COLUMN (FEATURES) ========== */
const RightColumn = styled.div`
  flex: 1;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns on larger screens */
  gap: 2rem;
  justify-items: center;

  /* Switch to single column on smaller screens */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureItem = styled.div`
  text-align: center;
  max-width: 240px;
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: #000;
  margin-bottom: 0.5rem;
`;

const FeatureHeading = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: ${brandBlue};
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
`;

/*
  Wrapper for the CTA button that appears on small/mid screens.
  Hidden on desktop (≥992px).
*/
const MobileCTAWrapper = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: block;
    margin-top: 2rem;
    text-align: center;
  }
`;

const MobileCTAButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

/* ========== COMPONENT ========== */
function HolisticApproachSection() {
  const goToFreePlan = useGoToFreePlan();

  return (
    <FeaturesWrapper>
      <ContentContainer>
        {/* LEFT COLUMN: Title, Subtitle, CTA (Desktop only) */}
        <LeftColumn>
            <SectionTitle>
            See What You Can Achieve with ClientCues
          </SectionTitle>
          <Subtitle>
            Uncover every user's or AI-agent's journey ,ask AI for instant clarity on obstacles improve call to action and uncovering hidden friction points, our platform empowers you to optimize experiences at every turn.

          </Subtitle>
          <DesktopCTAButton onClick={() => goToFreePlan('Holistic')}>
            Sign Up Now
          </DesktopCTAButton>
        </LeftColumn>

        {/* RIGHT COLUMN: Subsections */}
        <RightColumn>
          <FeaturesGrid>
            {/* 1) discover & watch */}
            <FeatureItem>
              <IconWrapper>
                <img
                  src={insights_reply}
                  alt="Insights and reply sessions"
                  style={{ width: '110px', height: '66px', objectFit: 'cover' }}
                />
              </IconWrapper>
              <FeatureHeading>discover & watch</FeatureHeading>
              <FeatureDescription>
                Surface friction points automatically and watch targeted replays for
                deeper context.
              </FeatureDescription>
            </FeatureItem>

            {/* 2) ask */}
            <FeatureItem>
              <IconWrapper>
                <MdQuestionAnswer />
              </IconWrapper>
              <FeatureHeading>ask</FeatureHeading>
              <FeatureDescription>
                  Pose direct product or marketing questions to AI and get detailed answers
                linked to relevant session highlights.
              </FeatureDescription>
            </FeatureItem>

            {/* 3) track */}
            <FeatureItem>
              <IconWrapper>
                <MdTrackChanges />
              </IconWrapper>
              <FeatureHeading>track</FeatureHeading>
              <FeatureDescription>
                Train a model using your feedback to monitor recurring issues
                and prevent user frustration.
              </FeatureDescription>
            </FeatureItem>

            {/* 4) act & integrate */}
            <FeatureItem>
              <IconWrapper>
                <MdBuild />
              </IconWrapper>
              <FeatureHeading>act & integrate</FeatureHeading>
              <FeatureDescription>
                 Convert insights into tasks and automate fixes with your favorite tools or
                live updates—all at scale.
              </FeatureDescription>
            </FeatureItem>

            {/* 5) llm-friendly */}
            <FeatureItem>
              <IconWrapper>
                <MdPsychology  />
              </IconWrapper>
              <FeatureHeading>llm-friendly</FeatureHeading>
              <FeatureDescription>
                Future-proof your site for AI-driven interactions and integrate
                with LLMs like OpenAI Operator.
              </FeatureDescription>
            </FeatureItem>
          </FeaturesGrid>

          {/* CTA on small/mid screens (below the feature items) */}
          <MobileCTAWrapper>
            <MobileCTAButton onClick={() => goToFreePlan('Holistic')}>
              Sign Up Now
            </MobileCTAButton>
          </MobileCTAWrapper>
        </RightColumn>
      </ContentContainer>
    </FeaturesWrapper>
  );
}

export default HolisticApproachSection;