import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import DropdownMenu from './DropdownMenu';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/img/logo/logoCc.png';
import {
  platformCategories,
  solutionsCategories,
  companyCategories
} from '../../constants/homepage-categories';

const FreePlanButton = styled.button`
  background-color: #0071e1;
  color: white;
  border: 1px solid #0071e1;
  &:hover {
    background-color: #005cbf;
    border-color: #005cbf;
  }
  border-radius: 7px;
  margin-left: 20px;
  padding: 5px 10px;

  /* You can adjust or remove this breakpoint logic as needed */
  @media (max-width: 992px) {
    margin: 10px 0;
  }
`;

function PublicNavbar() {
  const history = useHistory();

  const handleFreePlanClick = () => {
    history.push('/signup');
  };

  return (
    <Navbar expand="lg">
      <Container>
        {/* Logo + Mobile-Only Free Plan Button */}
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={logo}
            height="60"
            className="d-inline-block align-top"
            alt="Logo"
          />
          {/* This version of the button appears only below the lg breakpoint */}
          <div className="d-inline-block d-lg-none ms-2">
            <FreePlanButton onClick={handleFreePlanClick}>
              Register for free
            </FreePlanButton>
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <DropdownMenu title="Platform" categories={platformCategories} />
            <DropdownMenu title="Solutions" categories={solutionsCategories} />
            <DropdownMenu title="Company" categories={companyCategories} />
            <Nav.Link href="/pricing" className="nav-dropdown-title">
              Pricing
            </Nav.Link>
            <Nav.Link href="/login" className="nav-dropdown-title">
              Login
            </Nav.Link>
            {/* This version of the button is hidden below the lg breakpoint */}
            <FreePlanButton
              onClick={handleFreePlanClick}
              className="d-none d-lg-inline-block"
            >
              Register for free
            </FreePlanButton>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PublicNavbar;