import React from 'react';
import styled from 'styled-components';
import { brandBlue, brandYellow } from './colors';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';
import { Col } from 'reactstrap';
// If brandBlue isn't in your colors file, you can define:
// const brandBlue = '#0056b3';

const DifferenceWrapper = styled.section`
  background: linear-gradient(135deg, ${brandBlue} 0%, #00b3b3 100%);
  color: #fff;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
`;

const DifferenceContainer = styled.div`
  max-width: 1200px;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const PointsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const PointItem = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 1.5rem;
`;

const PointTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #fff;
`;

const PointText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #f1f1f1;
`;
const HeroButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    opacity: 0.8;
  }
`;

const NoCreditCardNote = styled.p`
  font-size: 0.9rem;
  color: #ddd;
  margin-top: 0.5rem;
   margin-left: 3.3rem;
`;

const ActionButtonWrapper = styled(Col)`

  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

export default function DifferenceSection() {
  const goToFreePlan = useGoToFreePlan();
  return (
    <DifferenceWrapper>
      <DifferenceContainer>
        <Title>Where ClientCues Makes All the Difference</Title>
        <Paragraph>
          Telling the full story behind the metrics. You already have funnels,
          conversion metrics, and even attribution data. But ClientCues goes deeper,
          explaining the story behind every user path—and letting you ask questions
          directly for deeper insights.
        </Paragraph>
        <PointsGrid>
          <PointItem>
            <PointTitle>Understanding user flows</PointTitle>
            <PointText>
              Session capture meets real-time analytics. ClientCues provides a precise
              breakdown of each step in the user journey.
            </PointText>
          </PointItem>
          <PointItem>
            <PointTitle>Pinpointing friction and confusion</PointTitle>
            <PointText>
              Whether it’s a complicated form or a slow-loading page, ClientCues flags it and
              quantifies the user impact.
            </PointText>
          </PointItem>
          <PointItem>
            <PointTitle>Proactive engagement strategies</PointTitle>
            <PointText>
              Say goodbye to reactive fixes. ClientCues helps you address issues in real
              time—so you can keep users engaged and loyal.
            </PointText>
          </PointItem>
          <PointItem>
            <PointTitle>Cross-functional collaboration</PointTitle>
            <PointText>
              From product managers to marketers, UX leads to customer success teams—everyone uses
              the same data and sees the same insights.
            </PointText>
          </PointItem>
        </PointsGrid>
         <ActionButtonWrapper>
           <div>
            <HeroButton onClick={() => goToFreePlan('Difference')}>Get free insights</HeroButton>
                <NoCreditCardNote>No credit card required</NoCreditCardNote>
             </div>
              </ActionButtonWrapper>
      </DifferenceContainer>
    </DifferenceWrapper>
  );
}