import React from 'react';
import styled from 'styled-components';
import { solutionsCategories } from '../../../shared/constants/homepage-categories';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';

// 1) MAIN WRAPPER
const UseCasesWrapper = styled.section`
  background: #fff;
  padding: 3rem 1rem;
  font-family: sans-serif;
  color: #333;
`;

// 2) CONTAINER WITH A HEADING & SUBHEADING
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const SectionHeading = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 0.5rem;

`;

const SectionSubheading = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 2rem;
  max-width: 700px;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
`;

// 3) ROW WITH TWO COLUMNS: “Use Cases” and “Teams”
const ColumnsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

// 4) INDIVIDUAL “CARD” FOR A CATEGORY
const CategoryCard = styled.div`
  flex: 1;
  max-width: 500px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.05);
  padding: 1.5rem;
  text-align: left;
  margin: 0 auto;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #333;
  }
`;

const CategoryList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const CategoryItem = styled.li`
  margin-bottom: 0.5rem;

  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }
`;

// 5) MAIN COMPONENT
export default function UseCasesAndTeamsSection() {
  const goToFreePlan = useGoToFreePlan();
  // your “Use Cases” data is solutionsCategories[0]; “Teams” is solutionsCategories[1]
  const additionalCategories = [
    solutionsCategories[0], // “Use Cases”
    solutionsCategories[1], // “Teams”
  ];

  return (
    <UseCasesWrapper>
      <Container>
        <SectionHeading>Explore Use Cases & Teams</SectionHeading>
        <SectionSubheading>
          Whether you need help prioritizing features, identifying new market
          opportunities, or aligning your teams more effectively—ClientCues
          has a solution for you.
        </SectionSubheading>

        <ColumnsWrapper>
          {additionalCategories.map((category, index) => (
            <CategoryCard key={index}>
              <h3>{category.title}</h3>
              <CategoryList>
                {category.items.map((item, subIndex) => (
                  <CategoryItem key={subIndex}>
                    <a href={item.href}>{item.text}</a>
                  </CategoryItem>
                ))}
              </CategoryList>
            </CategoryCard>
          ))}
        </ColumnsWrapper>
      </Container>
    </UseCasesWrapper>
  );
}