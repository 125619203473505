import React, { useState } from 'react';
import styled from 'styled-components';
import ContactUsModal from '../../shared/components/modals/ContactUsModal';
import { platformCategories, solutionsCategories, companyCategories } from '../../shared/constants/homepage-categories';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: rgb(35, 47, 62);
  padding: 40px 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
`;

const DecorativeLine = styled.div`
  height: 1px;
  background-color: #e0e0e0;
  width: 80%;
  margin: 0 auto 20px;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
  gap: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const FooterNav = styled.div`
  margin: 20px 0;

  h4 {
    margin-bottom: 15px;
    color: white;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 15px;
    text-align: left; // Ensure left alignment for list items
  }

  ul li {
    margin-bottom: 10px;
  }

  ul li a, p a {
    text-decoration: none;
    color: #007bff;
    transition: color 0.2s ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-top: 10px;
    max-width: 240px;
    color: #bbb; // Light grey color for the text
  }

  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #0056b3;
    }
  }

  @media (max-width: 768px) {
    text-align: center; // Center the text for h4
    ul {
      text-align: left; // Make sure list items remain left-aligned
    }
  }
`;

const CategoriesSection = styled.div`
  margin-bottom: 40px;
`;

const CategoryNav = styled.div`
  margin: 20px 0;

  h4 {
    margin-bottom: 15px;
    color: rgb(33, 37, 41);
    font-size: 1em;
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 15px;
    text-align: left; // Ensure left alignment for list items
  }

  ul li {
    margin-bottom: 10px;
  }

  ul li a {
    text-decoration: none;
    color: #6c757d;
    transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;

    &:hover {
      color: #007bff;
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    text-align: center; // Center the text for h4
    ul {
      text-align: left; // Make sure list items remain left-aligned
    }
  }
`;
const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;

  a {
    color: white;
    font-size: 24px;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #007bff;
    }
  }
`;
const FooterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const contactOptions = [
    { label: 'Partner with us', value: 'partner' },
    { label: 'Ask a question', value: 'question' },
    { label: 'Submit feedback', value: 'feedback' },
  ];

  const mainCategories = [
    { title: 'Platform', items: platformCategories[0].items },
    { title: 'Company', items: companyCategories[0].items },
  ];

  const additionalCategories = [
    { title: 'Use Cases', items: solutionsCategories[0].items },
    { title: 'Teams', items: solutionsCategories[1].items },
  ];

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {/*<CategoriesSection className='footer-categories-section'>*/}
      {/*  <div className='container'>*/}
      {/*    <div className='row'>*/}
      {/*      {additionalCategories.map((category, index) => (*/}
      {/*        <CategoryNav key={index} className='col'>*/}
      {/*          <h4>{category.title}</h4>*/}
      {/*          <ul>*/}
      {/*            {category.items.map((item, subIndex) => (*/}
      {/*              <li key={subIndex}><a href={item.href}>{item.text}</a></li>*/}
      {/*            ))}*/}
      {/*          </ul>*/}
      {/*        </CategoryNav>*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</CategoriesSection>*/}
      <FooterContainer className='sg-block page-footer'>
        <DecorativeLine />
        <FooterContent className='footer-container'>
          <FooterSection>
            <FooterNav className='footer-nav'>
              <p>Explore our journey and join us as we build a brighter future together.</p>
            </FooterNav>
            {mainCategories.map((category, index) => (
              <FooterNav key={index} className='footer-nav'>
                <h4>{category.title}</h4>
                <ul>
                  {category.items.map((item, subIndex) => (
                    <li key={subIndex}><a href={item.href}>{item.text}</a></li>
                  ))}
                </ul>
              </FooterNav>
            ))}
            <FooterNav className='footer-nav'>
              <h4>Legal</h4>
              <ul>
                <li><a href='/legal/privacy-policy'>Privacy Policy</a></li>
                <li><a href='/legal/terms-of-service'>Terms of Service</a></li>
                <li><a href='/legal/website-terms-of-use'>Website Terms of Use</a></li>
                <li><a href='/legal/dpa'>DPA</a></li>
              </ul>
            </FooterNav>

            <FooterNav className='footer-nav'>
              <p>Ensuring your privacy is our top priority.</p>
            </FooterNav>
            <FooterNav className='footer-nav'>
              <button onClick={handleOpenModal}>Contact Us</button>
              <ContactUsModal
                show={showModal}
                handleClose={handleCloseModal}
                options={contactOptions}
                defaultReason='feedback' // Default reason; adjust as needed
                userLoggedIn={false}
              />

              <SocialIcons>
                <a href="https://x.com/ClientCues" target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61559178522112" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF />
                </a>
                <a href="https://www.linkedin.com/company/clientcues" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn />
                </a>
              </SocialIcons>
                </FooterNav>
          </FooterSection>


        </FooterContent>
      </FooterContainer>

    </>
  );
};

export default Footer;