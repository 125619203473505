import React from 'react';
import styled from 'styled-components';
import { brandYellow } from './colors';
import TechnologyFlowAdvanced from './TechnologyFlowAdvanced';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';

const TechSectionWrapper = styled.section`
  background: #fff;
  color: #333;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
`;

const TechContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  gap: 3rem;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const Heading = styled.h2`
font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #555;
`;

const BulletList = styled.ul`
  list-style: inside;
  margin-bottom: 2rem;
  color: #555;
`;

const BulletItem = styled.li`
  margin-bottom: 0.75rem;
`;

const CtaButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;



export default function TechnologySection() {
  const goToFreePlan = useGoToFreePlan();
  return (
    <TechSectionWrapper>
      <TechContainer>
        <LeftColumn>
          <Heading>How Our Technology Gives You More</Heading>
          <Paragraph>
            The ClientCues platform is powered by a unique blend of proprietary
            machine-learning engines and advanced large language models (LLMs). We
            transform raw user data—from clickstreams to scroll depth—into a dynamic
            map of your users' experience.
          </Paragraph>
          <Paragraph>
            With our ChatGPT-style Q&amp;A interface, you can quickly get answers to
            business-critical questions in plain English—no data science degree needed.
            Our specialized architecture is built for speed, scale, and clarity,
            allowing you to move from “we think” to “we know” in seconds.
          </Paragraph>
          <BulletList>
            <BulletItem>Multi-dimensional session analysis: from page clicks to form interactions</BulletItem>
            <BulletItem>Journey friction discovery and tracking: see exactly where and why users struggle</BulletItem>
            <BulletItem>  Built for heavy-duty performance yet simple to onboard—no advanced tech
              skills needed: start getting insights in seconds</BulletItem>
          </BulletList>
          <CtaButton  onClick={() => goToFreePlan('Technology')} >Dive into the platform</CtaButton>
        </LeftColumn>

      </TechContainer>
    </TechSectionWrapper>
  );
}