import React from 'react';
import styled from 'styled-components';
import PublicNavbar from '../bars/PublicNavbar';
import Footer from '../../../components/Layout/Footer';
import { Helmet } from 'react-helmet';
import { htmlMeta } from '../../constants/html-meta';
import { getPublicUrl } from '../custom-routes/PublicUrl';

// Defining styled components
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const HeaderFixed = styled.header`
  position: fixed;
  //top: 0px;
  width: 100%;
  z-index: 1000; /* Ensure the header stays on top of other content */
  background-color: white; /* Use your desired background color */
 

  //&::after {
  //  background-color: #fafafb;
  //  border-radius: 1.25rem;
  //  content: "";
  //  height: calc(100% - 20px);
  //  left: 1.25rem;
  //  position: absolute;
  //  top: 1.25rem;
  //  width: calc(100% - 40px);
  //  z-index: -1; /* Using z-index: 20 might not place it behind the header content. Consider using z-index: -1 to ensure it's behind.*/
  //}
`;

const DivAboveHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure the header stays on top of other content */
  background-color: white; /* Use your desired background color */
  min-height: 20px;
`;

const defaultImage = getPublicUrl('/logo/logoCc.png');

// Modified PublicLayout component using styled-components
class PublicLayout extends React.Component {
  render() {
    const {
      children,
      title = htmlMeta.TITLE,
      description = htmlMeta.DESCRIPTION,
      image = defaultImage,
    } = this.props; // Default title is used when no title prop is passed
    return (
      <LayoutContainer>
        <Helmet>
          <title>{title}</title>
          <meta name='description' content={description} />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={description} />
          <meta property='og:image' content={image} />
          <meta name='twitter:image' content={image} />
          <meta name='twitter:card' content={image} />
        </Helmet>

        <HeaderFixed>
          <PublicNavbar />
        </HeaderFixed>
        <ContentWrapper>

          {children}
        </ContentWrapper>
        <Footer />
      </LayoutContainer>
    );
  }
}

export default PublicLayout;