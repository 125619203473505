import React from 'react';
import styled from 'styled-components';
import { brandYellow } from './colors';

/* Main wrapper for the entire diagram */
const DiagramWrapper = styled.section`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  font-family: sans-serif;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  color: #333;
  position: relative;

  /* Add some breathing room on smaller screens */
  @media (max-width: 900px) {
    padding: 1rem;
  }
`;

/* Hide lines on smaller screens to avoid them being cut off */
const ConnectLineMiddle = styled.svg`
  position: absolute;
  top: 230px;
  left: 35%;
  width: 20%;
  height: 200px;
  overflow: visible;

  @media (max-width: 900px) {
    display: none;
  }
`;
const ConnectLineLeft = styled.svg`
  position: absolute;
  top: 230px;
  left: 10%;
  width: 20%;
  height: 200px;
  overflow: visible;

  @media (max-width: 900px) {
    display: none;
  }
`;
const ConnectLineRight = styled.svg`
  position: absolute;
  top: 230px;
  left: 55%;
  width: 20%;
  height: 200px;
  overflow: visible;

  @media (max-width: 900px) {
    display: none;
  }
`;

/* Simple vertical lines from top row -> middle row, hidden on smaller screens */
const VerticalLine1 = styled.div`
  position: absolute;
  left: 50%;
  width: 2px;
  background: #aaa;
  z-index: -1;
  top: 220px;
  height: 70px;

  @media (max-width: 900px) {
    display: none;
  }
`;
const VerticalLine2 = styled.div`
  position: absolute;
  left: 50%;
  width: 2px;
  background: #aaa;
  top: 430px;
  height: 80px;

  @media (max-width: 900px) {
    display: none;
  }
`;

/* Top heading and subheading */
const DiagramHeading = styled.h2`
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 0.5rem;
`;
const DiagramSubheading = styled.p`
  text-align: center;
  color: #444;
  margin-bottom: 2rem;
  max-width: 700px;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
`;

/* Top row: data/enrichment source boxes */
const TopRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;

  /* Stack on smaller screens */
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
const SourceBox = styled.div`
  background: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 100%;
  max-width: 540px; /* Was fixed at 540px; now it can shrink on smaller screens */
  min-height: 80px;
  padding: 1rem;
  text-align: center;
  position: relative;
  font-size: 0.9rem;
  color: #555;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/* Middle row: three layer boxes + dotted arrow behind them horizontally */
const MiddleRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

/* Dotted arrow line behind the row, hidden on small screens */
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  height: 0;
  border-top: 2px dotted #aaa;
  z-index: -1;

  &::after {
    content: "→";
    position: absolute;
    right: -10px;
    top: -8px;
    font-size: 1.2rem;
    color: #999;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const LayerBox = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  max-width: 240px;
  min-height: 180px;
  padding: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  text-align: left;
`;

const LayerHeading = styled.h3`
  font-size: 1rem;
  margin-bottom: 1rem;
`;
const LayerList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  color: #555;
`;
const LayerItem = styled.li`
  position: relative;
  padding-left: 1.2rem;
  margin-bottom: 0.6rem;
  &::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #00b3b3;
  }
`;

/* Bottom bar + CTA */
const BottomBar = styled.div`
  background: #f2f6f9;
  border-radius: 6px;
  min-height: 50px;
  padding: 1rem;
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  margin-bottom: 2rem;
`;
const CtaContainer = styled.div`
  text-align: center;
`;
const CtaButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.9rem 1.5rem;
  font-weight: 600;
  font-size: 0.95rem;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    opacity: 0.8;
  }
`;

export default function TechFlowDiagram() {
  return (
    <DiagramWrapper>
      {/* Optionally connect top and middle row with vertical lines */}
      <VerticalLine1 />
      <VerticalLine2 />

      {/* Draw lines from top box to layer boxes */}
      <ConnectLineLeft>
        <line
          x1="75%"
          y1="0%"
          x2="75%"
          y2="100%"
          stroke="#aaa"
          strokeWidth="2"
          strokeDasharray="4,2"
          fill="none"
        />
      </ConnectLineLeft>
      <ConnectLineMiddle>
        <line
          x1="75%"
          y1="0%"
          x2="75%"
          y2="100%"
          stroke="#aaa"
          strokeWidth="2"
          strokeDasharray="4,2"
          fill="none"
        />
      </ConnectLineMiddle>
      <ConnectLineRight>
        <line
          x1="100%"
          y1="0%"
          x2="100%"
          y2="100%"
          stroke="#aaa"
          strokeWidth="2"
          strokeDasharray="4,2"
          fill="none"
        />
      </ConnectLineRight>

      {/* Top Title/Subheading */}
      <DiagramHeading>Much More Than Just “Session Recordings”</DiagramHeading>
      <DiagramSubheading>
        Most tools simply give you a replay of user clicks and scrolls. Our
        AI-driven approach uncovers the real “why” behind user behaviors,
        delivering automatic insights, friction detection, and ChatGPT-style
        Q&amp;A that goes far beyond standard session replay.
      </DiagramSubheading>

      {/* 1) Top row of data sources */}
      <TopRow>
        <SourceBox>
          We record session data from your site or app with our light-weighted
          script
        </SourceBox>
      </TopRow>

      {/* 2) Middle row: three layered boxes */}
      <MiddleRow>
        <Arrow />
        <LayerBox>
          <LayerHeading>Recording &amp; Collection</LayerHeading>
          <LayerList>
            <LayerItem>Capture every session—no sampling</LayerItem>
            <LayerItem>Track clicks, scrolls, and form data</LayerItem>
            <LayerItem>Automated event indexing</LayerItem>
            <LayerItem>Supports web &amp; mobile</LayerItem>
          </LayerList>
        </LayerBox>
        <LayerBox>
          <LayerHeading>AI &amp; Insights</LayerHeading>
          <LayerList>
            <LayerItem>Highlight friction &amp; churn triggers</LayerItem>
            <LayerItem>Auto-tag user behaviors</LayerItem>
            <LayerItem>Ask GPT for real-time Q&amp;A</LayerItem>
            <LayerItem>Discover hidden user patterns</LayerItem>
          </LayerList>
        </LayerBox>
        <LayerBox>
          <LayerHeading>Action &amp; Follow-Through</LayerHeading>
          <LayerList>
            <LayerItem>Resolve issues faster with direct replay links</LayerItem>
            <LayerItem>Real-time dashboards &amp; alerts</LayerItem>
            <LayerItem>Integrate with Slack, Jira, or custom webhooks</LayerItem>
            <LayerItem>Close the loop on user feedback</LayerItem>
          </LayerList>
        </LayerBox>
      </MiddleRow>

      {/* 3) Bottom bar + CTA */}
      <BottomBar>Works seamlessly with your existing workflows</BottomBar>
      <CtaContainer>
        <CtaButton>Let’s Put Your Session Data to Work</CtaButton>
      </CtaContainer>
    </DiagramWrapper>
  );
}