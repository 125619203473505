import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout';
import HomePage from '../../../pages/public/HomePage';


import { NavigationContext } from '../../../context/NavigationContext';


import PublicRoute from './PublicRoute';
import { useAuth } from '../../../context/AuthContext';
import FeedbackAnalysisPage from '../../../pages/private/feedback/FeedbackAnalysisPage';
import CustomTopicsPage from '../../../pages/private/feedback/CustomTopicsPage';
import FeedbackAskAI from '../../../pages/private/feedback/FeedbackAskAI';
import DynamicNotFound from '../../../pages/public/DynamicNotFound';
import FullPivotPage from '../../../pages/public/FullPivotPage';


const TeamsPage = lazy(() => import('../../../pages/public/TeamsPage'));
const UseCasesPage = lazy(() => import('../../../pages/public/UseCasesPage'));
const AboutUsPage = lazy(() => import('../../../pages/public/AboutUsPage'));
const SupportPage = lazy(() => import('../../../pages/public/SupportPage'));
const PrivacySecurityPage = lazy(() => import('../../../pages/public/PrivacySecurityPage'));
const TermsOfService = lazy(() => import('../../../pages/public/components/terms-of-service/TermsOfService'));
const WebsiteTermsOfUse = lazy(() => import('../../../pages/public/components/website-terms-of-use/WebsiteTermsOfUse'));
const DPA = lazy(() => import('../../../pages/public/components/dpa/DPA'));
const PrivacyPolicy = lazy(() => import('../../../pages/public/components/privacy-policy/PrivacyPolicy'));
const SubscriptionPage = lazy(() => import('../../../pages/private/account/SubscribePage'));
const GetStartedPage = lazy(() => import('../../../pages/private/home/GetStartedPage'));
const NotFoundPage = lazy(() => import('../../../pages/public/NotFoundPage'));
const AppLayout = lazy(() => import('../layouts/AppLayout'));
const AppRoute = lazy(() => import('./AppRoute'));
const BlogPage = lazy(() => import('../../../pages/public/BlogPage'));
const BlogPostPage = lazy(() => import('../../../pages/public/BlogPostPage'));
const ScreenReplyPage = lazy(() => import('../../../pages/private/screen_record/ScreenReplyPage'));
const PricingPage = lazy(() => import('../../../pages/public/PricingPage'));
const AuthPage = lazy(() => import('../../../pages/public/AuthPage'));

let redirectPath = '/';
const RouteTable = () => {
  const { isLoading, isAuthenticated } = useAuth();
  const generateRoutesFromCategories = (categories, basePath = '/') => {
    let routes = [];

    categories.forEach((category) => {
      // Compute the full path
      let fullpath = category.path ? `${basePath}/${category.path}`.replace(/\/+/g, '/') : '';

      // Prepare component props if any
      const componentProps = category.params ? { ...category.params } : {};

      // If the category has a component, create a route for it
      if (category.component) {
        const route = (

          <AppRoute
            key={fullpath} // Ensure keys are unique
            exact={!!category.exact} // Use the exact flag from the category if provided
            path={fullpath}
            layout={(props) => (

              <AppLayout {...props} />


            )}
            component={(props) => React.createElement(category.component, { ...props, ...componentProps })}
          />
        );
        routes.push(route);
      }

      // If the category has children, recursively generate routes for them
      if (category.children) {
        const childRoutes = generateRoutesFromCategories(category.children, fullpath);
        routes = routes.concat(childRoutes); // Merge child routes into the current routes array
      }
    });

    return routes;
  };


  if (isLoading) {
    return <div></div>; // Or a loading spinner component
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PublicRoute exact path='/' layout={PublicLayout} component={FullPivotPage} redirectPath={redirectPath}
                     title={'Enhance User Experience with Insights and Feedback'}
                     description={'Gain insights into user interactions, uncover usability issues, and enhance product experience with our comprehensive session replay and customer feedback tools.'} />
        <Route
          path={['/login', '/signup', '/reset_password', '/verify_email', '/link_validate_email', '/forgot_password', '/link_validate_email_set_password']}
          exact component={AuthPage} />
        <PublicRoute exact path='/teams/*' layout={PublicLayout} component={TeamsPage} redirectPath={redirectPath}
                     title={'Help Your Teams Improve with User Insights and Feedback'}
                     description={'Learn how different teams within your company can benefit from insights into user interactions, enhanced user experience, and detailed feedback.'} />
        <PublicRoute exact path='/use-cases/*' layout={PublicLayout} component={UseCasesPage}
                     redirectPath={redirectPath}
                     title={'Real Use Cases: Enhancing Customer Experience and Feedback Collection'}
                     description={'Explore how our platform helps uncover usability issues, enhance user interactions, and improve customer feedback.'} />
        <PublicRoute exact path='/pricing' layout={PublicLayout} component={PricingPage} redirectPath={redirectPath}
                     title={'Unparalleled Pricing for Gathering Feedback and Session Recording'}
                     description={'Discover our cost-effective solutions for recording sessions, analyzing customer feedback, and building feature-rich roadmaps to enhance user experience.'} />
        <PublicRoute exact path='/company/blog' layout={PublicLayout} component={BlogPage} redirectPath={redirectPath}
                     title={'Unlocking Customer Success: Insights & Strategies - Blog'}
                     description={'Uncover platform pricing secrets & build roadmaps users adore! Leverage NPS, session recordings & in-app surveys. Free Trial to boost product management & reduce churn.'} />
        <PublicRoute exact path='/company/blog/posts/*' layout={PublicLayout} component={BlogPostPage}
                     redirectPath={redirectPath} />
        <PublicRoute exact path='/company/about-us' layout={PublicLayout} component={AboutUsPage}
                     redirectPath={redirectPath} title={'About Us: Our Mission to Enhance User Experience'}
                     description={'Learn about our journey and commitment to enhancing user experience through actionable insights and customer feedback.'} />
        <PublicRoute exact path='/company/support' layout={PublicLayout} component={SupportPage}
                     redirectPath={redirectPath} title={'ClientCues Support: Here When You Need Us'}
                     description={'Our support team is ready to assist you with any query, custom integration, or dashboard configurations to enhance your product experience.'} />
        <PublicRoute exact path='/company/security' layout={PublicLayout} component={PrivacySecurityPage}
                     redirectPath={redirectPath} title={'Security at ClientCues: Protecting User Data'}
                     description={'Learn about our robust security measures designed to protect user data and ensure secure session recordings and customer feedback.'} />
        <PublicRoute exact path='/legal/terms-of-service' layout={PublicLayout} component={TermsOfService}
                     redirectPath={redirectPath} title={'Terms of Service: Your Agreement with ClientCues'}
                     description={'Read our Terms of Service to understand your rights and responsibilities when using our session recording and feedback collection services.'} />
        <PublicRoute exact path='/legal/website-terms-of-use' layout={PublicLayout} component={WebsiteTermsOfUse}
                     redirectPath={redirectPath} title={'Website Terms of Use: ClientCues'}
                     description={'Understand the terms governing your use of our website and services, including session replay and user feedback features.'} />
        <PublicRoute exact path='/legal/dpa' layout={PublicLayout} component={DPA} redirectPath={redirectPath}
                     title={'Data Processing Agreement (DPA)'}
                     description={'Our DPA outlines the terms for data processing activities related to session replay and customer feedback collection.'} />
        <PublicRoute exact path='/legal/privacy-policy' layout={PublicLayout} component={PrivacyPolicy}
                     redirectPath={redirectPath} title={'Privacy Policy: Protecting Your Data at ClientCues'}
                     description={'Learn how we protect your privacy and handle data collected during user sessions and customer feedback collection.'} />

        <AppRoute
          key='/settings/edit-plan' // Ensure keys are unique
          exact={true} // Use the exact flag from the category if provided
          path='/settings/edit-plan'
          layout={(props) => (
            <AppLayout {...props} />
          )}
          component={(props) => <PricingPage isPublic={false} />}

        />
        <AppRoute
          key='/settings/subscribe' // Ensure keys are unique
          exact={true} // Use the exact flag from the category if provided
          path='/settings/subscribe'
          layout={(props) => (
            <AppLayout {...props} />
          )}
          component={(props) => <SubscriptionPage />}

        />

        <AppRoute
          key='/home/get-started' // Ensure keys are unique
          exact={true} // Use the exact flag from the category if provided
          path='/home/get-started'
          layout={(props) => (
            <AppLayout {...props} />
          )}
          component={(props) => <GetStartedPage />}

        />

        <AppRoute
          key='/feedback/analysis' // Ensure keys are unique
          exact={true} // Use the exact flag from the category if provided
          path='/feedback/analysis'
          layout={(props) => (
            <AppLayout {...props} />
          )}
          component={(props) => <FeedbackAnalysisPage />}

        />

        <AppRoute
          key='/feedback/ask_ai' // Ensure keys are unique
          exact={true} // Use the exact flag from the category if provided
          path='/feedback/ask_ai'
          layout={(props) => (
            <AppLayout {...props} />
          )}
          component={(props) => <FeedbackAskAI />}

        />

        <AppRoute
          key='/feedback/validate' // Ensure keys are unique
          exact={true} // Use the exact flag from the category if provided
          path='/feedback/validate'
          layout={(props) => (
            <AppLayout {...props} />
          )}
          component={(props) => <CustomTopicsPage />}

        />


        <AppRoute
          key='/session_replay/replay_session' // Ensure keys are unique
          exact={true} // Use the exact flag from the category if provided
          path='/session_replay/replay_session'
          layout={(props) => (
            <AppLayout {...props} />
          )}
          component={(props) => <ScreenReplyPage />}

        />

        {isAuthenticated &&
          <NavigationContext.Consumer>
            {({ categoryList }) => categoryList ?
              generateRoutesFromCategories(categoryList)
              : null //or some loading spinner
            }
          </NavigationContext.Consumer>
        }


        <Route
          render={(props) => {
            // Our custom catch-all:
            return <DynamicNotFound {...props} />;
          }}
        />
      </Switch>
    </Suspense>
  );
};

export default RouteTable;