import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TbWand } from 'react-icons/tb';
import { fetchSummary, fetchInsights, fetchFeedback } from './components/MockGenerator'; // Mock data functions
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { create_custom_topic } from '../../../api/customTrendsRequests';
import { askOpenQuestionRequest } from '../../../api/askAiRequests';

// Styled components
const AppContainer = styled.div`
  padding: 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 10px;
  width: 100%;
`;

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
  width: 70%;
  max-width: 600px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const AskAIButton = styled.button`
  padding: 10px;
  margin-left: 10px;
  border-radius: 4px;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #2980b9;
  }
`;

const ResultsContainer = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin-bottom: 10px;
`;

const TicketsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;
`;

const Ticket = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
`;

const TicketTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ExpandButton = styled.button`
  margin-bottom: 5px;
  padding: 0;
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const FullText = styled.div`
  font-size: 0.9em;
  margin-bottom: 5px;
  white-space: pre-wrap;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`;

const TicketLink = styled.a`
  color: #0366d6;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SimilarityLevel = styled.div`
  font-size: 0.9em;
  color: #555;
`;

const InfoText = styled.div`
  font-size: 0.8em;
  color: #666;
  padding: 5px 0;
  text-align: left;
`;

const predefinedQuestions = [
  'Give me the top 5 places where users struggle.',
  'Identify where users often stop their usage.',
  'Which features correlate with increased drop-offs?',
  'List session recordings mentioning errors.',
  'Do users mention anything about performance?',
  'What do customers say about the new onboarding flow?',
];

const FeedbackAskAI = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [summary, setSummary] = useState('');
  const [insights, setInsights] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [trendData, setTrendData] = useState([]);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputRef = useRef(null);
  const history = useHistory();
  const [responseText, setResponseText] = useState(''); // Holds the AI response

  useEffect(() => {
    if (searchTerm) {
      async function fetchData() {
        const summaryResult = await fetchSummary(searchTerm);
        setSummary(summaryResult);

        const insightsResult = await fetchInsights(searchTerm);
        setInsights(insightsResult);

        const feedbackResult = await fetchFeedback(searchTerm);
        setFeedback(feedbackResult.results);
        setTotalCount(feedbackResult.totalCount);

        const trend = [
          { name: 'Jan', count: 15 },
          { name: 'Feb', count: 30 },
          { name: 'Mar', count: 50 },
          { name: 'Apr', count: 75 },
        ];
        setTrendData(trend);
      }

      fetchData();
    }
  }, [searchTerm]);

  const handleSearch = async () => {
    setDropdownVisible(false);
    const trimmed = inputValue.trim();
    if (!trimmed) return;

    try {
      const { data } = await askOpenQuestionRequest({}, trimmed);
      // The Flask endpoint returns { response: "some text" }
      setResponseText(data.response || 'No response');
    } catch (error) {
      console.error('Error calling ask_open_question:', error);
      setResponseText('Failed to get a response from AI.');
    }
  };

  const handleQuestionSelect = (question) => {
    setInputValue(question);
    setDropdownVisible(false);
    inputRef.current?.focus();
  };

  const toggleTextDisplay = (index) => {
    setExpandedIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const truncateText = (text, wordsCount) => {
    const words = text.split(' ');
    return words.length > wordsCount ? words.slice(0, wordsCount).join(' ') + '...' : text;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setDropdownVisible(e.target.value === '');
  };

  const handleRefineResults = async () => {
    try {
      const response = await create_custom_topic(null, searchTerm);

      const customTrendId = response.data.custom_trend_id;
      history.push(`/feedback/custom_topics?custom_topic_id=${customTrendId}`);
    } catch (error) {
      console.error('Failed to create custom trend:', error);
    }
  };

  return (
    <AppContainer>
      <SearchContainer>
        <SearchWrapper>
          <SearchInput
            ref={inputRef}
            type='text'
            placeholder='Enter questions about your feedback'
            value={inputValue}
            onFocus={() => {
              if (inputValue === '') setDropdownVisible(true);
            }}
            onBlur={() => setDropdownVisible(false)}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
          />
          {dropdownVisible && (
            <Dropdown>
              {predefinedQuestions.map((question, index) => (
                <DropdownItem key={index} onMouseDown={() => handleQuestionSelect(question)}>
                  {question}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
          <AskAIButton onClick={handleSearch}>
            <TbWand style={{ marginRight: '5px' }} /> Ask AI
          </AskAIButton>
        </SearchWrapper>
      </SearchContainer>
      <ResultsContainer>
        <InfoText>
          {/*{totalCount} Results Found*/}
          {searchTerm && totalCount > 0 && (
            <button onClick={handleRefineResults} style={{ marginLeft: '10px', cursor: 'pointer' }}>
              Refine Results
            </button>
          )}
        </InfoText>
        <div>
          <strong>AI Response:</strong>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {responseText}
          </p>
        </div>
        <div>
          <strong>General Trend:</strong>
          <BarChart width={500} height={300} data={trendData}>
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Bar dataKey='count' fill='#8884d8' />
          </BarChart>
        </div>
        <div>
          <strong>Insights:</strong>
          {insights.map((insight, index) => (
            <div key={index}>
              <strong>{insight.insight}</strong>
              {insight.trend}
            </div>
          ))}
        </div>
      </ResultsContainer>
      <TicketsContainer>
        {feedback.length === 0 ? (
          <p>No similar feedback found.</p>
        ) : (
          feedback.map((item, index) => {
            const words = item.text ? item.text.split(' ') : [];
            const isExpanded = expandedIndexes.includes(index);
            return (
              <Ticket key={item.feedback_text_id}>
                {item.title && <TicketTitle>{item.title}</TicketTitle>}
                <FullText>
                  {isExpanded ? item.text : truncateText(item.text, 30)}
                </FullText>
                {words.length > 30 && (
                  <ExpandButton onClick={() => toggleTextDisplay(index)}>
                    {isExpanded ? 'See Less' : 'See More'}
                  </ExpandButton>
                )}
                <BottomContainer>
                  <TicketLink href={item.link || '#'} target='_blank'>
                    View Original
                  </TicketLink>
                  <SimilarityLevel>
                    Similarity: {calculateSimilarityLevel(item.score)}
                  </SimilarityLevel>
                </BottomContainer>
              </Ticket>
            );
          })
        )}
      </TicketsContainer>
    </AppContainer>
  );
};

const calculateSimilarityLevel = (score) => {
  if (score >= 0.5) return 'High';
  if (score >= 0.25) return 'Medium';
  return 'Low';
};

export default FeedbackAskAI;