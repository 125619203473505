const platformCategories = [
    {
        title: 'Explore our platform',
        items: [
//        { text: 'Platform Overview', href: '/platform/overview' },
            {text: 'Security & Privacy', href: '/company/security'},
            {text: 'Plans & Pricing', href: '/pricing'},
        ],

    },
];


// Solutions Categories and Items
const solutionsCategories = [

    {
        title: 'Use Cases',
        items: [
              {text: 'Go-to-Market Alignment', href: '/use-cases/go-to-market-alignment'},
             {text: 'Market Trend Analysis', href: '/use-cases/market-trend-analysis'},
            {text: 'Product Roadmaps', href: '/use-cases/product-roadmap'},
            {text: 'Customer Insights', href: '/use-cases/customer-insights'},
            {text: 'Data-Driven Prioritization', href: '/use-cases/data-driven-prioritization'},
            {text: 'Reduce Churn', href: '/use-cases/reduce-churn'},
            {text: 'Strategic Product Planning', href: '/use-cases/strategic-product-planning'},
            {text: 'Cross-Team Collaboration', href: '/use-cases/cross-team-collaboration'},
            {text: 'Competitive Advantage', href: '/use-cases/competitive-advantage'},
            {text: 'Operational Efficiency', href: '/use-cases/operational-efficiency'},
            {text: 'Customer Segment Identification', href: '/use-cases/customer-segment-identification'},
            {text: 'Complaint Resolution Tactics', href: '/use-cases/complaint-resolution-tactics'},
            {text: 'Crisis Management and Response', href: '/use-cases/crisis-management-and-response'},
            {
                text: 'Content and Marketing Strategy Refinement',
                href: '/use-cases/content-and-marketing-strategy-refinement',
            },
            {text: 'Employee Satisfaction and Engagement', href: '/use-cases/employee-satisfaction-and-engagement'},
            // {
            //   text: 'Sustainability and Social Responsibility Initiatives',
            //   href: '/use-cases/sustainability-and-social-responsibility-initiatives',
            // },
            // { text: 'Cultural and Demographic Insights', href: '/use-cases/cultural-and-demographic-insights' },
            // { text: 'Legal and Compliance Implications', href: '/use-cases/legal-and-compliance-implications' },
            // { text: 'Accessibility Improvements', href: '/use-cases/accessibility-improvements' },
            // { text: 'Technology Adoption and Utilization', href: '/use-cases/technology-adoption-and-utilization' },
            //
            // { text: 'Workplace Culture Enhancement', href: '/use-cases/workplace-culture-enhancement' },
            // { text: 'Employee Retention Strategies', href: '/use-cases/employee-retention-strategies' },
            // {
            //   text: 'Training and Development Needs Identification',
            //   href: '/use-cases/training-and-development-needs-identification',
            // },
            // { text: 'Internal Process Optimization', href: '/use-cases/internal-process-optimization' },
            // { text: 'Enhancing Leadership and Management', href: '/use-cases/enhancing-leadership-and-management' },
            // {
            //   text: 'Identifying and Mitigating Workforce Burnout',
            //   href: '/use-cases/identifying-and-mitigating-workforce-burnout',
            // },
            // {
            //   text: 'Improving Employee Recognition and Rewards',
            //   href: '/use-cases/improving-employee-recognition-and-rewards',
            // },
            // { text: 'Building a Feedback-Driven Culture', href: '/use-cases/building-a-feedback-driven-culture' },

        ],
    },
    {
        title: 'Teams',
        'items': [
               {'text': 'Marketing', 'href': '/teams/marketing'},
             {'text': 'User Experience (UX) / User Interface (UI) Design', 'href': '/teams/ux-ui-design'},
            {'text': 'Customer Experience', 'href': '/teams/customer-experience'},
            {'text': 'Product Managers', 'href': '/teams/product-managers'},
            {'text': 'Customer Service / Support', 'href': '/teams/customer-service-support'},
            {'text': 'Sales', 'href': '/teams/sales'},
            {'text': 'Technical Support', 'href': '/teams/technical-support'},
            {'text': 'Account Management', 'href': '/teams/account-management'},
            {'text': 'Customer Success', 'href': '/teams/customer-success'},
            {'text': 'Quality Assurance', 'href': '/teams/quality-assurance'},
            {'text': 'Feedback & Research', 'href': '/teams/feedback-research'},
            {'text': 'Community Management', 'href': '/teams/community-management'},
            {'text': 'Product Development', 'href': '/teams/product-development'},
            {'text': 'Compliance & Regulatory Affairs', 'href': '/teams/compliance-regulatory-affairs'},
        ],
    },
];

// Company Categories and Items
const companyCategories = [

    {
        title: 'Resources',
        items: [
            {text: 'Blog', href: '/company/blog'},
            {text: 'Help & Support', href: '/company/support'},
            {text: 'Security & Privacy', href: '/company/security'}
        ],
    },

    // {
    //   title: 'Technology',
    //   items: [
    //     { text: 'Improve NPS with our Instant suggestions', href: '#careers' },
    //     { text: 'Churn reduction analysis', href: '#careers' },
    //     { text: 'Smart walkthroughes using AI', href: '#careers' },
    //     { text: 'Automatic roadmap suggestions', href: '#careers' },
    //     { text: 'AI session analysis', href: '#internships' },
    //   ],
    // },
];

module.exports = {
    platformCategories,
    solutionsCategories,
    companyCategories,
};