import React from 'react';
import styled from 'styled-components';

const IndustriesWrapper = styled.section`
  background: #fafafa;
  color: #333;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
`;

const IndustriesContainer = styled.div`
  max-width: 1200px;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: bold;
`;

const IndustriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const IndustryCard = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  padding: 1.5rem;
`;

const IndustryTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #2b00b3;
`;

const IndustryText = styled.p`
  font-size: 1rem;
  line-height: 1.4;
  color: #555;
`;

export default function IndustriesSection() {
  return (
    <IndustriesWrapper>
      <IndustriesContainer>
        <Title>Industries</Title>
        <IndustriesGrid>
          <IndustryCard>
            <IndustryTitle>Travel &amp; Hospitality</IndustryTitle>
            <IndustryText>
              Elevate guest experiences and reduce churn with real-time insights.
            </IndustryText>
          </IndustryCard>
          <IndustryCard>
            <IndustryTitle>Financial Services</IndustryTitle>
            <IndustryText>
              Enhance digital onboarding and resolve pain points to bolster trust.
            </IndustryText>
          </IndustryCard>
          <IndustryCard>
            <IndustryTitle>Education</IndustryTitle>
            <IndustryText>
              Pinpoint and fix drop-offs in online learning to keep students engaged.
            </IndustryText>
          </IndustryCard>
          <IndustryCard>
            <IndustryTitle>Retail</IndustryTitle>
            <IndustryText>
              Identify funnel leaks and optimize for conversions.
            </IndustryText>
          </IndustryCard>
          <IndustryCard>
            <IndustryTitle>Technology</IndustryTitle>
            <IndustryText>
              Prioritize features that matter for user adoption and growth.
            </IndustryText>
          </IndustryCard>
          <IndustryCard>
            <IndustryTitle>Entertainment &amp; Gaming</IndustryTitle>
            <IndustryText>
              Analyze player engagement and quickly resolve friction seamlessly.
            </IndustryText>
          </IndustryCard>
        </IndustriesGrid>
      </IndustriesContainer>
    </IndustriesWrapper>
  );
}