import React from 'react';
import styled from 'styled-components';
import { brandGreen, brandYellow } from './colors'; // Adjust or remove if not needed
import hero_section_image from '../../../assets/img/homepage/sections/hero_section_image.png';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';
import { Col, Row } from 'reactstrap';

// 1) HERO LAYOUT
const HeroWrapper = styled.section`
  position: relative;
  min-height: 900px;
  padding: 7rem 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(135deg, #2b00b3 0%, ${brandGreen} 100%);
  color: #fff;

  @media (max-width: 1100px) {
    min-height: 700px;
    padding-bottom: 2em;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1400px;
  width: 100%;
  padding: 2rem;
`;

const HeroContent = styled.div`
  max-width: 600px;
  margin-right: 2rem;
`;

const HeroHeading = styled.h1`
  font-size: 2.6rem; /* Slightly increased font size */

  //margin-bottom: 0.5em;
  font-weight: bold;
`;

const HeroParagraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

// Optional bullet list
const CheckList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
`;

const CheckListItem = styled.li`
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;

  &::before {
    content: "✔";
    position: absolute;
    left: 0;
    color: #00b300;
    font-weight: bold;
  }
`;

const HeroButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    opacity: 0.8;
  }
`;

// Bottom Triangle Decoration
const TriangleBottom = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;

  svg {
    display: block;
    width: calc(100% + 1.3px);
    height: 100%;
  }

  .shape-fill {
    fill: #fff;
  }

  @media (max-width: 1100px) {
    display: none;
  }

`;

const HeroHeading2 = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1.25em;
  line-height: 1.4;
  opacity: 0.9;
`;

const HeroSubHeading = styled.h2`
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1.25em;
  line-height: 1.4;
  opacity: 0.9;
`;
const NoCreditCardNote = styled.p`
  font-size: 0.9rem;
  color: #ddd;
  margin-top: 0.5rem;
   margin-left: 3.3rem;
`;
export default function HeroSection() {
  const goToFreePlan = useGoToFreePlan();
  return (
    <HeroWrapper>
      <Col style={{ maxWidth: '1400px' }}>
        <Row justify-content-center>
          <HeroContainer>
            <Row>
              <Col xs={12} lg={6}>
                <HeroContent>
                  <HeroHeading>
                    Instantly Know Who’s Struggling and Why
                  </HeroHeading>
                  <HeroHeading2>
                     & Get your website AI-Ready faster
                  </HeroHeading2>

                  <HeroSubHeading>
                    No more endless replays - get insights on user sessions in seconds with AI & ask questions to uncover user frustrations and improvement ideas.

                  </HeroSubHeading>

                  {/*<HeroSubHeading>*/}
                  {/*  Perfect for product managers, marketing teams, UX designers, and founders seeking clear user*/}
                  {/*  insights.*/}
                  {/*</HeroSubHeading>*/}
                  <CheckList>
                    <CheckListItem>Capture every session for full user journeys</CheckListItem>
                    <CheckListItem>Actionable advice to fine-tune messaging, layout, and user flows</CheckListItem>
                    <CheckListItem>Ask AI questions instead of manually digging through replays</CheckListItem>
                    <CheckListItem>Get your website AI-Ready faster with insights on AI-agent journey</CheckListItem>
                  </CheckList>


                </HeroContent>
              </Col>
              {/* Replacing the MockUI with a PNG image */}
              <Col xs={12} lg={6}>
                <div>
                  <img
                    src={hero_section_image}
                    alt='Hero Section Mock'
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              </Col>

            </Row>

          </HeroContainer>
        </Row>
        <Row>
          <Col>
            <div>
            <HeroButton onClick={() => goToFreePlan('Hero')}>Get free insights</HeroButton>
                <NoCreditCardNote>No credit card required</NoCreditCardNote>
              </div>
          </Col>
        </Row>
      </Col>
      <TriangleBottom>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 150'
          preserveAspectRatio='none'
        >
          <path d='M0,150L800,0L1200,150H0z' className='shape-fill'></path>
        </svg>
      </TriangleBottom>
    </HeroWrapper>
  );
}