import React from 'react';
import styled from 'styled-components';
import { brandGreen, brandYellow } from './colors';
import ImpactIllustration from './ImpactIllustration';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';

const ImpactSectionWrapper = styled.section`
  background: linear-gradient(135deg, #2b00b3 0%, ${brandGreen} 100%);
  color: #fff;
  padding: 5rem 2rem;
  display: flex;
  justify-content: center;

  /* Reduce padding on extra-small screens so content doesn’t overflow */
  @media (max-width: 600px) {
    padding: 3rem 1rem;
  }
`;

const ImpactContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: flex-start;

  /* Stack columns and center them for widths under 900px */
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const BulletList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
`;

const BulletItem = styled.li`
  margin-bottom: 1rem;
  padding-left: 1.8rem;
  position: relative;

  &::before {
    content: "•";
    position: absolute;
    left: 0;
    color: ${brandYellow};
    font-size: 1.5rem;
    line-height: 1rem;
  }
`;

const DemoButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Let the illustration shrink on narrower screens */
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

export default function BuiltImpactSection() {
  const goToFreePlan = useGoToFreePlan();

  return (
    <ImpactSectionWrapper>
      <ImpactContainer>
        <LeftColumn>
          <Title>Built to Drive Strategic Cross-Organizational Impact</Title>
          <Paragraph>
            From pinpointing specific points of friction to diagnosing drop-off causes,
            ClientCues ensures that every data point you collect from user interactions
            translates into measurable improvements in conversion, retention, and satisfaction.
          </Paragraph>
          <BulletList>
            <BulletItem>Advanced user journey analysis</BulletItem>
            <BulletItem>Rich session replays &amp; event stream data</BulletItem>
            <BulletItem>Friction monitoring &amp; anomaly detection</BulletItem>
            <BulletItem>Proactively catches negative signals</BulletItem>
            <BulletItem>Root-cause analysis &amp; exploration</BulletItem>
            <BulletItem>Investigate in real time why users abandon</BulletItem>
            <BulletItem>Customizable tagging &amp; categorization</BulletItem>
            <BulletItem>Tailor to capture nuances for your brand</BulletItem>
          </BulletList>
          <DemoButton onClick={() => goToFreePlan('buildImpact')}>
            Start building
          </DemoButton>
        </LeftColumn>

        <RightColumn>
          <ImpactIllustration />
        </RightColumn>
      </ImpactContainer>
    </ImpactSectionWrapper>
  );
}