import React from 'react';
import styled from 'styled-components';
import { brandGreen } from './colors';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';

const AiSectionWrapper = styled.section`
  background: #fff;
  color: #333;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
`;

const AiContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin-bottom: 2rem;
`;

const ReasonsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  max-width: 800px;
  margin-top: 2rem;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const ReasonItem = styled.div`
  background: #f7f7f7;
  border-radius: 6px;
  padding: 1.5rem;
  text-align: left;
`;

const ReasonTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
 
`;

const ReasonText = styled.p`
  font-size: 1rem;
  color: #555;
`;

export default function AiModelSection() {
  const goToFreePlan = useGoToFreePlan();
  return (
    <AiSectionWrapper>
      <AiContainer>
        <Title>Forget About Generic AI Models</Title>
        <Paragraph>
          ClientCues redefines intelligence by merging cutting-edge machine learning
          with advanced large language models for an unparalleled view of real user
          interactions. Our powerful back-end and intuitive front-end empower you to explore
          user journeys, detect emerging friction, and track patterns with unmatched precision.
        </Paragraph>
        <ReasonsGrid>
          <ReasonItem>
            <ReasonTitle>Precision-Driven Insights</ReasonTitle>
            <ReasonText>
              ClientCues AI is custom-trained on your data, giving you hyper-relevant analyses.
            </ReasonText>
          </ReasonItem>
          <ReasonItem>
            <ReasonTitle>Real-Time Responsiveness</ReasonTitle>
            <ReasonText>
              Processes session data quickly, letting you act on insights instantly.
            </ReasonText>
          </ReasonItem>
          <ReasonItem>
            <ReasonTitle>Multi-Layered Analysis</ReasonTitle>
            <ReasonText>
              Multiple dimensions—pages, time on task, clicks, etc.—all integrated for a full picture.
            </ReasonText>
          </ReasonItem>
          <ReasonItem>
            <ReasonTitle>Powerful Yet User-Friendly</ReasonTitle>
            <ReasonText>
              ChatGPT-style Q&amp;A, no data science background required.
            </ReasonText>
          </ReasonItem>
        </ReasonsGrid>
      </AiContainer>
    </AiSectionWrapper>
  );
}