import React from 'react';
import styled from 'styled-components';
import { brandBlue, brandYellow } from './colors';
import { useGoToFreePlan } from '../../../shared/components/custom-routes/navigationElements/GoToFreePlan';
import { Row } from 'reactstrap';
// If brandBlue is not in colors, define it like: const brandBlue = "#0056b3";

const RoiWrapper = styled.section`
  background: #0056b3;
  color: #fff;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
`;

const RoiContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const BulletList = styled.ul`
  list-style: inside;
  margin: 0 auto 2rem auto;
  max-width: 800px;
  text-align: left;
`;

const BulletItem = styled.li`
  margin-bottom: 0.75rem;
`;

const CtaButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    opacity: 0.8;
  }
`;


const TestimonialCard = styled.div`
  max-width: 800px;
  //width: 100%;
  background: #fff; /* White “card” background */
  border-radius: 6px; /* Soften corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
`;

const Quote = styled.blockquote`
  display: inline;
  font-style: italic;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;

  &:before {
    content: '“';
    font-size: 1.5rem;
    color: #555;
    margin-right: 3px;
  }

  &:after {
    content: '”';
    font-size: 1.5rem;
    color: #555;
  }
`;

const Author = styled.p`
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1rem;
  color: #555;
`;


export default function RoiSection() {
  const goToFreePlan = useGoToFreePlan();
  return (
    <RoiWrapper>
      <RoiContainer>
        <Title>Maximize ROI on Your Existing Resources</Title>
        <Paragraph>
          With ClientCues, you simply embed our script to record sessions and start capturing data.
          From there, our advanced analytics and ChatGPT-style Q&amp;A transform raw session replays
          into strategy-ready insights—no complicated setup required.
        </Paragraph>
        <BulletList>
          <BulletItem>Seamless integration: Start seeing insights quickly</BulletItem>
          <BulletItem>Use your existing tech stack—no re-platforming needed</BulletItem>
          <BulletItem>Focus on actionable insights instead of data wrangling</BulletItem>
        </BulletList>
        <Paragraph>
          We handle the heavy lifting of data analysis, so your team can focus on delivering the
          best possible user experience.
        </Paragraph>
        <Row className="justify-content-center">
          <TestimonialCard>
            <Quote>
              Before ClientCues, we spent hours sifting through endless replays just to guess at user
              pain points. Now, we get concise text summaries, actionable suggestions, and clear proof—
              no more marathon replay sessions.
            </Quote>
            <Author>Head of Product – Large Accessibility CO.</Author>
          </TestimonialCard>
        </Row>
        <CtaButton onClick={() => goToFreePlan('ROI')}>Get Started</CtaButton>
      </RoiContainer>

    </RoiWrapper>
  );
}
