import React from 'react';
import styled from 'styled-components';

const TestimonialWrapper = styled.section`
  /* Match or slightly transition from the ROI’s #0056b3 to the footer’s #232F3E.
     You can just pick one color or do a gradient. */
  background: #0056b3; 
  /* background: linear-gradient(to bottom, #0056b3, #232F3E); */
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
`;

const TestimonialCard = styled.div`
  max-width: 800px;
  width: 100%;
  background: #fff;    /* White “card” background */
  border-radius: 6px;  /* Soften corners */
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  padding: 2rem;
  text-align: center;
`;

const Quote = styled.blockquote`
  display: inline; 
  font-style: italic;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;

  &:before {
    content: '“';
    font-size: 1.5rem;
    color: #555;
    margin-right: 3px;
  }
  &:after {
    content: '”';
    font-size: 1.5rem;
    color: #555;
  }
`;

const Author = styled.p`
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1rem;
  color: #555;
`;

export default function TestimonialSection() {
  return (
    <TestimonialWrapper>
      <TestimonialCard>
        <Quote>
          Before ClientCues, we spent hours sifting through endless replays just to guess at user
          pain points. Now, we get concise text summaries, actionable suggestions, and clear proof—
          no more marathon replay sessions.
        </Quote>
        <Author>Head of Product – Large Accessibility CO.</Author>
      </TestimonialCard>
    </TestimonialWrapper>
  );
}