import React from 'react';
import HeroSection from './new-home-page/HeroSection';
import TrustedBySection from './new-home-page/TrustedBySection';
import HolisticApproachSection from './new-home-page/HolisticApproachSection';
import RecordingsSection from './new-home-page/RecordingsSection';
import BuiltImpactSection from './new-home-page/BuiltImpactSection';
import TechnologySection from './new-home-page/TechnologySection';
import DifferenceSection from './new-home-page/DifferenceSection';
import IndustriesSection from './new-home-page/IndustriesSection';
import CtaBanner from './new-home-page/CtaBanner';
import TestimonialSection from './new-home-page/TestimonialSection';
import AiModelSection from './new-home-page/AiModelSection';
import RoiSection from './new-home-page/RoiSection';
import FooterSection from './new-home-page/FooterSection';
import TechnologyDiagram from './new-home-page/TechnologyDiagram';
import UseCasesAndTeamsSection from './new-home-page/UseCasesAndTeamsSection';

export default function FullPivotPage() {
  return (
    <div style={{ width: '100%', overflow: 'hidden', fontFamily: 'Arial, sans-serif' }}>
      <HeroSection />
      <HolisticApproachSection />
      {/*<TrustedBySection />*/}
      <RecordingsSection />
      <TechnologySection />
      <TechnologyDiagram />

      <BuiltImpactSection />
      <AiModelSection />
      <DifferenceSection />
      {/*<IndustriesSection />*/}
      {/*<CtaBanner />*/}


      <UseCasesAndTeamsSection />
      <RoiSection />
      {/*<TestimonialSection />*/}
      {/*<FooterSection />*/}
    </div>
  );
}