import React from 'react';
import styled from 'styled-components';
import { brandBlue, brandYellow } from './colors';
// If brandPurple isn't in your colors file, use: const brandPurple = '#2b00b3';

const BannerWrapper = styled.section`
  background: ${brandBlue || '#2b00b3'};
  color: #fff;
  text-align: center;
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
`;

const BannerContainer = styled.div`
  max-width: 1000px;
  width: 100%;
`;

const BannerHeading = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const BannerParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const DemoButton = styled.button`
  background-color: ${brandYellow};
  color: #000;
  border: none;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export default function CtaBanner() {
  return (
    <BannerWrapper>
      <BannerContainer>
        <BannerHeading>Let’s Start Putting Your Session Data to Work</BannerHeading>
        <BannerParagraph>
          Take the next step and see how ClientCues can transform your user insights.
        </BannerParagraph>
        <DemoButton>Book a Demo</DemoButton>
      </BannerContainer>
    </BannerWrapper>
  );
}