import React from 'react';
import styled from 'styled-components';

/* WRAPPER around the entire illustration */
const IllustrationWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 1rem auto;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  font-family: sans-serif;
  color: #333;
  position: relative;

  @media (max-width: 600px) {
    max-width: 100%;
    padding: 0.5rem;
  }
`;

/* HEADINGS */
const Title = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  text-align: center;
  color: #2b00b3;

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const SubText = styled.p`
  max-width: 600px;
  margin: 0.5rem auto 1.5rem auto;
  text-align: center;
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
`;

/* “Dashboard” mock
   - Keep a fixed height on large screens
   - Reduce height on smaller screens via media query
*/
const DashboardMock = styled.div`
  width: 100%;
  max-width: 700px;
  height: 300px; /* Keep the original look on bigger screens */
  background: #fafafa;
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    max-width: 100%;
    height: 220px; /* Shrink to avoid overflow on very small devices */
  }
`;

const DashboardTopBar = styled.div`
  height: 32px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;

const TrafficLight = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props) => props.color || '#ccc'};
  border-radius: 50%;
  margin-right: 5px;
`;

const DashboardContent = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

/* LEFT DIAGRAM AREA */
const DiagramArea = styled.div`
  flex: 1;
  position: relative;
  background: linear-gradient(135deg, #f9f9ff 0%, #ffffff 100%);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DottedPath = styled.div`
  position: absolute;
  width: 70%;
  height: 2px;
  border-top: 2px dotted #bbb;
  top: 50%;
  left: 15%;
`;

const DataPoint = styled.div`
  width: 12px;
  height: 12px;
  background: ${(props) => props.color || '#1DD8BE'};
  border-radius: 50%;
  position: absolute;
`;

/* RIGHT HORIZONTAL ROW of info “cards” */
const InfoCards = styled.div`
  flex: 1;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  overflow-x: auto;
  overflow-y: hidden;
`;

const InfoCard = styled.div`
  flex: 0 0 auto;
  width: 180px;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background: ${(props) => props.bg || '#2b00b3'};
  border-radius: 4px;
  flex-shrink: 0;
`;

const CardText = styled.div`
  font-size: 0.85rem;
  color: #555;
  line-height: 1.3;
`;

export default function ImpactIllustration() {
  return (
    <IllustrationWrapper>
      {/* HEADINGS */}
      <Title>Built to Drive Strategic Cross-Organizational Impact</Title>
      <SubText>
        From pinpointing friction points to diagnosing drop-off causes,
        ClientCues translates every user-data point into measurable
        improvements in conversion, retention, and satisfaction.
      </SubText>

      {/* “Dashboard” style illustration */}
      <DashboardMock>
        <DashboardTopBar>
          <TrafficLight color="#ED594A" />
          <TrafficLight color="#FDD800" />
          <TrafficLight color="#5AC05A" />
        </DashboardTopBar>

        <DashboardContent>
          {/* LEFT DIAGRAM AREA */}
          <DiagramArea>
            <DottedPath />
            <DataPoint style={{ top: '40%', left: '25%' }} />
            <DataPoint style={{ top: '60%', left: '55%' }} color="#00b3b3" />
            <DataPoint style={{ top: '45%', left: '70%' }} color="#FFC900" />
          </DiagramArea>

          {/* RIGHT HORIZONTAL ROW OF CARDS */}
          <InfoCards>
            <InfoCard>
              <IconWrapper bg="#1DD8BE" />
              <CardText>
                <strong>Advanced user journeys</strong>
                <br />
                Map &amp; understand each user’s path.
              </CardText>
            </InfoCard>

            <InfoCard>
              <IconWrapper bg="#FFC600" />
              <CardText>
                <strong>Rich session replays</strong>
                <br />
                Capture every click &amp; scroll.
              </CardText>
            </InfoCard>

            <InfoCard>
              <IconWrapper bg="#FF8A65" />
              <CardText>
                <strong>Friction monitoring</strong>
                <br />
                Detect &amp; resolve negative signals.
              </CardText>
            </InfoCard>

            <InfoCard>
              <IconWrapper bg="#A66FFF" />
              <CardText>
                <strong>Root-cause exploration</strong>
                <br />
                See why users abandon in real time.
              </CardText>
            </InfoCard>

            <InfoCard>
              <IconWrapper bg="#00b3b3" />
              <CardText>
                <strong>Tagging &amp; categorization</strong>
                <br />
                Customize for your brand’s nuances.
              </CardText>
            </InfoCard>
          </InfoCards>
        </DashboardContent>
      </DashboardMock>
    </IllustrationWrapper>
  );
}