import { useHistory } from 'react-router-dom';

export function useGoToFreePlan() {
  const history = useHistory();

  // Accept a source parameter (e.g. "heroBanner", "ctaBanner", "footer", etc.)
  const goToFreePlan = (source = 'default') => {
    // Append a query param so you know the CTA’s source
    history.push(`/signup?source=${source}`);
  };

  return goToFreePlan;
}