import React from 'react';
import styled from 'styled-components';

/**
 * This is our reusable “page-like” section wrapper:
 *  - Default height: 1000px; if you pass large={true}, it becomes 1300px.
 *  - Optionally a big polygon that overlaps the bottom, filled with nextBgColor,
 *    to hint the user that another page is below.
 */

const SectionWrapper = styled.section`
  position: relative;
  height: ${props => (props.large ? '1300px' : '1000px')};
  padding: 4rem 2rem;
  background: ${props => props.bgColor || '#fff'};
  color: ${props => props.fontColor || '#333'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const SectionContent = styled.div`
  position: relative;
  max-width: 900px;
  text-align: left;
  z-index: 2;
`;

const BottomPolygon = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1000px; /* Large shape so it covers big area */
  z-index: 1;
  pointer-events: none;
`;

function PageSection({
  children,
  bgColor = '#fff',
  fontColor = '#333',
  hasBottomPolygon = true,
  nextBgColor = '#fff',
  large = false,
}) {
  return (
    <SectionWrapper bgColor={bgColor} fontColor={fontColor} large={large}>
      <SectionContent>
        {children}
      </SectionContent>
      {hasBottomPolygon && (
        <BottomPolygon viewBox="0 0 1440 1000" preserveAspectRatio="none">
          {/*
            Big trapezoid/parallelogram polygon to overlap bottom portion,
            filled by nextBgColor. Adjust points to taste.
          */}
          <polygon
            fill={nextBgColor}
            points="
              0,1000
              0,300
              600,150
              1440,400
              1440,1000
            "
          />
        </BottomPolygon>
      )}
    </SectionWrapper>
  );
}

export default PageSection;